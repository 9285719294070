<template>
  <div id="credit">
    <h1>Credits</h1>
  </div>
</template>

<script>
export default {
  name: 'Credits'
}
</script>